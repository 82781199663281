var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"mb-5"},[_c('p',[_vm._v(" This portal, "),_c('strong',[_vm._v("SherlockIT Portugal Data Check")]),_vm._v(", is a product dedicated to provide timely business intelligence on Portugal related data. You could learn more about SherlockIT on the "),_c('router-link',{attrs:{"to":"/faq"}},[_vm._v("FAQ")]),_vm._v(" section or if you would like to see more information about "),_c('span',{staticClass:"text-danger"},[_vm._v("data")]),_c('span',{staticClass:"text-primary"},[_vm._v("fields")]),_vm._v(" please visit our main web site "),_c('a',{attrs:{"href":"https://www.datafields.net","target":"blank"}},[_vm._v("www.datafields.net")]),_vm._v(". ")],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-7"},[_c('h1',{staticClass:"display-3 text-center"},[_vm._v("Subscription-Plans")]),_c('h1',{staticClass:"display-4"},[_vm._v(" SherlockIT is a product developed by Datafields. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"lead"},[_c('strong',[_vm._v("Datafields: Data services for legal, risk, credit and insurance industries.")])]),_c('p',[_c('span',{staticClass:"text-danger"},[_vm._v("data")]),_c('span',{staticClass:"text-primary"},[_vm._v("fields")]),_vm._v(" specialises in gathering and formatting large amounts of unstructured public data and convert them to searchable digital libraries. Our experience and technology have led us to develop and deploy more than 900 automated data-acquisition scripts to build our digital libraries and MIS systems for leading companies worldwide. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"lead"},[_c('strong',[_vm._v("We build and operate the world's smartest data-acquisition robots.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"lead"},[_c('strong',[_vm._v(" We have the know how to build lighting fast search technology. ")])]),_c('p',[_c('span',{staticClass:"text-danger"},[_vm._v("data")]),_c('span',{staticClass:"text-primary"},[_vm._v("fields")]),_vm._v(" can build data from any source - PDF, Word, XL, CSV or any other format. We excel at converting data into a searchable format useful for fast searching, indexing and data-feeding such as XML. We can even mine valuable data from legacy systems. We are forefront in Data services for legal, risk, credit and insurance industries, in our chosen market sectors ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"lead"},[_c('strong',[_vm._v(" Get intelligent, actionable business data in real time.")])]),_c('p',[_c('span',{staticClass:"text-danger"},[_vm._v("data")]),_c('span',{staticClass:"text-primary"},[_vm._v("fields")]),_vm._v(" gathers, organises legal-related documents to bring legal-data solutions including: ")]),_c('div',[_c('ul',[_c('li',[_vm._v(" Insolvency and bankruptcy notifications ")]),_c('li',[_vm._v("Public and legal notices")]),_c('li',[_vm._v(" Court documents and case law ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',{staticClass:"text-danger"},[_vm._v("data")]),_c('span',{staticClass:"text-primary"},[_vm._v("fields")]),_vm._v(" has built large, multilingual, searchable legal data sets incorporating local, state and regional information from court proceedings in many different countries throughout Europe, the UK, Asia, Latin America and Africa. For tailor made solutions please contact, datafields ")])])
}]

export { render, staticRenderFns }