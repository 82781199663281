<template>
  <div class="container">
    <div class="mt-7">
      <h1 class="display-3 text-center">Subscription-Plans</h1>
      <h1 class="display-4">
        SherlockIT is a product developed by Datafields.
      </h1>
    </div>
    <div>
      <p class="lead">
        <strong
          >Datafields: Data services for legal, risk, credit and insurance
          industries.</strong
        >
      </p>
      <p>
        <span class="text-danger">data</span
        ><span class="text-primary">fields</span> specialises in gathering and
        formatting large amounts of unstructured public data and convert them to
        searchable digital libraries. Our experience and technology have led us
        to develop and deploy more than 900 automated data-acquisition scripts
        to build our digital libraries and MIS systems for leading companies
        worldwide.
      </p>
    </div>
    <div>
      <p class="lead">
        <strong
          >We build and operate the world's smartest data-acquisition
          robots.</strong
        >
      </p>
    </div>
    <div>
      <p class="lead">
        <strong>
          We have the know how to build lighting fast search technology.
        </strong>
      </p>
      <p>
        <span class="text-danger">data</span
        ><span class="text-primary">fields</span> can build data from any source
        - PDF, Word, XL, CSV or any other format. We excel at converting data
        into a searchable format useful for fast searching, indexing and
        data-feeding such as XML. We can even mine valuable data from legacy
        systems. We are forefront in Data services for legal, risk, credit and
        insurance industries, in our chosen market sectors
      </p>
    </div>
    <div>
      <p class="lead">
        <strong>
          Get intelligent, actionable business data in real time.</strong
        >
      </p>
      <p>
        <span class="text-danger">data</span
        ><span class="text-primary">fields</span> gathers, organises
        legal-related documents to bring legal-data solutions including:
      </p>
      <div>
        <ul>
          <li>
            Insolvency and bankruptcy notifications
          </li>
          <li>Public and legal notices</li>
          <li>
            Court documents and case law
          </li>
        </ul>
      </div>
    </div>
    <div>
      <p>
        <span class="text-danger">data</span
        ><span class="text-primary">fields</span> has built large, multilingual,
        searchable legal data sets incorporating local, state and regional
        information from court proceedings in many different countries
        throughout Europe, the UK, Asia, Latin America and Africa. For tailor
        made solutions please contact, datafields
      </p>
    </div>
    <div class="mb-5">
      <p>
        This portal, <strong>SherlockIT Portugal Data Check</strong>, is a
        product dedicated to provide timely business intelligence on Portugal
        related data. You could learn more about SherlockIT on the
        <router-link to="/faq">FAQ</router-link> section or if you would like to
        see more information about <span class="text-danger">data</span
        ><span class="text-primary">fields</span> please visit our main web site
        <a href="https://www.datafields.net" target="blank"
          >www.datafields.net</a
        >.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  metaInfo: {
    title: "Subscription Plans"
  },
  components: {}
};
</script>